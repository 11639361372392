<template>
  <div class="home">
    <v-layout row wrap>
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text"><router-link to="/">Dashboard</router-link> / Support Cases</h1>
      </v-flex>
      <v-flex xs12 class="mb-5">
        <v-layout row wrap class="d-flex justify-space-between align-center">
          <v-flex xs8 md6 lg4>
            <v-text-field
              single-line
              outlined
              v-model="searchText"
              label="Type to search.."
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </v-flex>
          <v-flex xs4 md4 lg4 class="d-flex justify-end">
            <v-btn class="primary black--text" x-large @click="addCase">Create Case</v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left uppercase primary--text">#</th>
                <th class="text-left uppercase primary--text">
                  Case Id
                </th>
                <th class="text-left uppercase primary--text">
                  Booking Id
                </th>
                <th class="text-left uppercase primary--text">Name</th>
                <th class="text-left uppercase primary--text">Status</th>
                <th class="text-left uppercase primary--text">Created</th>
                <th class="text-left uppercase primary--text">Updated</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,index) in filteredCases" :key="item._id">
                <td>{{ index + 1 }}</td>
                <td>{{ item.complaint_no }}</td>
                <td>{{ item.booking_id }}</td>
                <td>{{ item.name }}</td>
                <td class="accent--text"><b>{{ item.status }}</b></td>
                <td>{{ item.createdAt | moment("dddd, MMMM Do YYYY, h:mm:ss a") }}</td>
                <td>{{ item.updatedAt | moment("dddd, MMMM Do YYYY, h:mm:ss a")}}</td>
                <td>
                  <v-btn @click="viewCase(item._id)"
                    ><v-icon class="primary--text">mdi-magnify</v-icon></v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import {BASE_URL} from '../../config'
import Axios from 'axios'
export default {
  data(){
    return{
      cases : [],
      searchText : ""
    }
  },
  mounted() {
      this.fetchCases()
  },
  computed: {
    filteredCases() {
      const query = this.searchText.toLowerCase();
      if (query.length >= 3) {
        return this.cases.filter((item) =>
          JSON.stringify(item).toLowerCase().includes(query)
        );
      } else {
        return this.cases;
      }
    },
  },
  methods : {
    async fetchCases(){
      let url = BASE_URL + "/support"
      let {data} = await Axios.get(url)
      this.cases = data
    },
    addCase(){
        this.$router.push('/support/create-case')
    },
    viewCase(id){
      this.$router.push({name : 'CaseDetail',params : {id : id}})
    }
  }
}
</script>